import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, ListItem, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Chip } from '../../atoms/Chip';
import dateFormat from 'dateformat';
import DefaultImg from '../../../assets/images/default-image.jpeg';

interface BlogListItemProps extends BlogImgWrapperProps, BlogImgProps, BlogTechCategories {
  title?: string;
  date?: string;
  impact?: string;
  articleType: string;
  technology: string;
  author?: string;
  description?: string;
  link?: { pathname: string; state: any };
}
interface BlogImgWrapperProps {
  width?: string;
  imgWrapperWidth?: string;
  children?: React.ReactNode;
  link?: { pathname: string; state: any };
}
interface BlogImgProps {
  alt?: string;
  image?: string;
  imgWidth?: string;
}

interface BlogTechCategories {
  tech_categories?: Array<{
    id: number;
    name: string;
    slug: string;
  }>;
}

const KbBlogListItem = styled(ListItem)(({ theme }) => ({
  paddingBottom: '1rem',
  borderBottom: '0.063rem solid #BEBEBE',
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
  textDecoration: 'none',
  cursor: 'pointer',
  gap: '1rem',
  alignItems: 'flex-start',
  width: '100%',
  '&:first-child': {
    paddingTop: '1rem',
  },
  '&:last-child': {
    borderBottom: 0,
    marginBottom: 0,
    padding: 0,
  },
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .blogSecondaryText': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.5rem',
  },
  '& .mediaRight': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },

  '& .blogListItem': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  '& .line-clamp-2': {
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
  },
  '& .fs-14': {
    fontSize: '.875rem',
  },
  '& .blogTitle': {
    fontWeight: 500,
    lineHeight: '1.4',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '.5rem',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.grey[600],
    },
  },
  '& .listItems': {
    width: '100%',
  },
}));

export const BlogListItem = ({
  title,
  date,
  impact,
  imgWrapperWidth,
  alt,
  image,
  imgWidth,
  link = { pathname: '', state: '' },
  description,
}: BlogListItemProps) => {
  const navigate = useNavigate();
  return (
    <KbBlogListItem
      disablePadding={true}
      className='listItems'
      onClick={() => navigate(link.pathname, { state: { ...link.state } })}
    >
      <BlogImgWrapper
        imgWrapperWidth={imgWrapperWidth}
        link={link}
        children={<BlogImg alt={alt} image={image} imgWidth={imgWidth} />}
      />
      <Box marginLeft={1} className='listItems'>
        <Box className='blogListItem'>
          <Box marginBottom={2}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant='h4' className='blogTitle'>
                {title}
              </Typography>
              <Chip impact={impact} />
            </Box>
            <Typography variant='body1' display={'block'} color='#888'>
              {dateFormat(date, `d-mmm-yyyy`)}
            </Typography>
          </Box>

          <Typography variant='h6' className='line-clamp-2 fs-14'>
            {description}
          </Typography>
        </Box>
        <Box component='div' className='blogSecondaryText'>
          <Box></Box>
        </Box>
      </Box>
    </KbBlogListItem>
  );
};

const KbBlogImgWrapper = styled(Link)<BlogImgWrapperProps>(({ width }) => ({
  width: '3rem',
  marginTop: '0',
  display: 'block',
  height: 'auto',
  flexShrink: '0',
  borderRadius: '0.375rem',
}));

export const BlogImgWrapper = ({
  imgWrapperWidth,
  children,
  link = { pathname: '', state: '' },
}: BlogImgWrapperProps) => {
  return (
    <KbBlogImgWrapper to={link.pathname} state={link.state} width={imgWrapperWidth}>
      {children}
    </KbBlogImgWrapper>
  );
};

const KbBlogImg = styled('img')({
  objectFit: 'cover',
  borderRadius: '0.375rem',
});

export const BlogImg = ({ alt, image, imgWidth = '100%' }: BlogImgProps) => {
  return (
    <>
      {image ? <KbBlogImg alt={alt} src={image} width={imgWidth} /> : <KbBlogImg src={DefaultImg} width={imgWidth} />}
    </>
  );
};
