import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DefaultVideoImg from '../../../../assets/images/video-placeholder.png';
import { isRegularVideo, isYoutubeVideo } from '../../../../Utils/helper';
import { KbDrawerHeader } from '../../../atoms/Card/navigationCard';
import Icon from '../../../Icons/icons';

const ModalContent = styled(Box)(({ theme }) => ({
  maxWidth: '850px',
  margin: 'auto',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  '&:focus-visible': {
    outline: 'none',
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  paddingLeft: '.5rem',
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '400px',
  marginBottom: '3rem',
  overflow: 'hidden',
}));

const IframeStyle = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 0,
  borderRadius: '0.5rem',
});

const VideoStyle = styled('video')({
  width: '100%',
  height: '100%',
  borderRadius: '0.5rem',
});

const ImageStyle = styled('img')({
  width: '100%',
  height: '100%',
  border: 0,
  borderRadius: '0.5rem',
});

const CloseButton = styled(Box)(({ theme }) => ({
  paddingInline: '.5rem',
  cursor: 'pointer',
}));

interface TechEventsModalProps {
  openModal: boolean;
  handleCloseModal: (event: any) => void;
  data: any;
}

const TechEventsModal: React.FC<TechEventsModalProps> = ({ openModal, handleCloseModal, data }) => {
  const mediaFile = data?.attributes?.media_url;
  const mediaUrlToDisplay = isYoutubeVideo(mediaFile) ? `${mediaFile}?autoplay=1` : mediaFile;

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <ModalContent>
        <KbDrawerHeader>
          <ModalTitle variant='h4'>{data?.attributes?.event_type}</ModalTitle>
          <CloseButton onClick={handleCloseModal}>
            <Icon kind='close' size={12} />
          </CloseButton>
        </KbDrawerHeader>

        <Box sx={{ padding: '1.5rem' }}>
          <VideoContainer>
            {isYoutubeVideo(mediaFile) ? (
              <IframeStyle
                title='tech-events-iframe'
                src={`${mediaUrlToDisplay}&autoplay=1`}
                allow='autoplay'
                loading='lazy'
              />
            ) : isRegularVideo(mediaFile) ? (
              <VideoStyle controls autoPlay muted>
                <source src={mediaFile} type='video/mp4' />
                Your browser does not support the video tag.
              </VideoStyle>
            ) : (
              <ImageStyle src={DefaultVideoImg} alt='placeholder' />
            )}
          </VideoContainer>
          <Typography variant='body2' color='textSecondary'>
            By {data?.attributes?.presenter}
          </Typography>
          <Typography variant='body2' color='textSecondary' paragraph>
            Published on{' '}
            {new Date(data?.attributes?.publishedAt)?.toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default TechEventsModal;
