import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const KbHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginBottom: "2.5rem",
  fontWeight: 500,
}));

interface HeaderProps {
  align?: any;
  title: string | number | ReactNode;
  variant?: any;
  className?: string;
  icon?: ReactNode;
  sx?: Object;
}

export const Heading = ({ align, title, variant, className, icon, sx }: HeaderProps) => {
  return (
    <KbHeading align={align} variant={variant} className={className} sx={sx ? sx : {}}>
      {icon}
      {title}
    </KbHeading>
  );
};
