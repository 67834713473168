import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { RightSideContent } from '../../Components/molecules/RightSideBar';
import { TechEventsCard } from '../../Components/atoms/Card/techEventsCard';
import { formatDate } from '../../Utils/helper';
import TechEventsModal from '../../Components/molecules/TechEvents/TechEventsModal';
import { ITechEvent } from '../../types/TechDigest';

interface TechEventsInterface {
  data: ITechEvent[] | undefined;
}

export const TechEvents = ({ data }: TechEventsInterface) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTechEvent, setSelectedTechEvent] = useState<any>({});

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, event?: any) => {
    e.stopPropagation();
    setIsModalOpen((prev) => !prev);
    setSelectedTechEvent(event);
  };

  return (
    <RightSideContent>
      <Grid container spacing={3}>
        {data?.length ? (
          data?.map((event: any, index: number) => (
            <Grid item xs={3} key={index}>
              <div onClick={(e) => handleCloseModal(e, event)}>
                <TechEventsCard
                  date={formatDate(event?.attributes?.date)}
                  title={event?.attributes?.event_type}
                  mediaUrl={event?.attributes?.media_url}
                  alt={event?.attributes?.event_type}
                  presenter={event?.attributes?.presenter}
                />
              </div>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant='h3' className='noDataText'>
              Sorry, we couldn't find any results
            </Typography>
          </Grid>
        )}
      </Grid>

      <TechEventsModal handleCloseModal={handleCloseModal} openModal={isModalOpen} data={selectedTechEvent} />
    </RightSideContent>
  );
};
