import { useState, useCallback } from 'react';
import { MeiliSearch, SearchResponse } from 'meilisearch';
import { ISearch } from '../types/Search';

// Initialize the Meilisearch client
const client: any = new MeiliSearch({
  host: process.env.REACT_APP_MEILI_SEARCH_URL || '',
  apiKey: process.env.REACT_APP_MEILI_SEARCH_API_KEY || '',
});

interface UseMeiliMultiSearchReturn {
  searchResult: ISearch[];
  searchIndexes: (query: string) => Promise<void>;
  loading: boolean;
  error: Error | null;
}

const useMeiliSearch = (): UseMeiliMultiSearchReturn => {
  const [searchResult, setSearchResult] = useState<ISearch[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const searchIndexes = useCallback(async (query: string) => {
    if (!query) {
      setSearchResult([]);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const searchResults: SearchResponse<any> = await client.index('tech-digest').search(query);
      setSearchResult(searchResults?.hits);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { searchResult, searchIndexes, loading, error };
};

export default useMeiliSearch;
