import { Box, Container } from '@mui/material';
import { Categories } from '../../Components/molecules/Categories';
import { Filters } from '../../Components/molecules/Filters';
import { RecentUpdates } from '../../Components/molecules/RecentUpdates';
import { TechEvents } from '../../Components/molecules/TechEvents';

const Dashboard = () => {
  return (
    <Box>
      <Filters />
      <Container>
        <Categories />
        <RecentUpdates />
        <TechEvents />
      </Container>
    </Box>
  );
};

export default Dashboard;
