import { Box, Card, Typography, styled } from '@mui/material';
import dateFormat from 'dateformat';
import DefaultVideoImg from '../../../assets/images/video-placeholder.png';

interface TechEventsProps {
  date: string;
  title: string;
  mediaUrl: string;
  alt: string;
  presenter: string;
}

const KbTechEventsCard = styled(Card)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  marginBottom: '1rem',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: '1rem',
  cursor: 'pointer',
  '& .heading': {
    fontWeight: '500',
    marginBottom: '1rem',
  },
  '& .imgWrapper': {
    width: '100%',
    height: '8.125rem',
    marginTop: '0',
    display: 'flex',
    flexShrink: '0',
    borderRadius: '0.375rem',
    marginBottom: '1rem',
    position: 'relative',
    backgroundColor: '#F8F8F8',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    '& img': {
      objectFit: 'cover',
      borderRadius: '0.375rem',
      width: '100%',
    },
    '& video': {
      width: '100%',
      borderRadius: '0.375rem',
    },
  },
  '& .ifram-tech-events': {
    width: '100%',
    border: '0',
    overflow: 'hidden',
    borderRadius: '0.375rem',
  },
}));

const Title = styled(Typography)(({}) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  lineClamp: 2,
  lineHeight: 1.2,
  flex: 1,
  marginBottom: '1rem',
}));

export const TechEventsCard = ({ date, title, mediaUrl, alt, presenter }: TechEventsProps) => {
  const extractYouTubeId = (url: string) => {
    const youtubePattern =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+|\S+\/\S+|\S+\/\S+|\S+)?(?:v=|embed\/)([a-zA-Z0-9_-]+))|(?:youtu\.be\/([a-zA-Z0-9_-]+))/;

    const match = url.match(youtubePattern);
    return match ? match[1] || match[2] : null;
  };

  // Function to get YouTube thumbnail URL
  const getYouTubeThumbnailUrl = (videoId: string) => {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const videoId = extractYouTubeId(mediaUrl);

  return (
    <KbTechEventsCard>
      <Box className='imgWrapper'>
        {mediaUrl && (
          <>
            {videoId ? (
              <img src={getYouTubeThumbnailUrl(videoId)} alt={alt} />
            ) : (
              <img src={DefaultVideoImg} alt={alt} />
            )}
          </>
        )}
      </Box>
      <Title variant='h6' gutterBottom>
        {title}
      </Title>
      <Typography variant='body2' color='textSecondary'>
        By {presenter}
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        Published on {dateFormat(date, 'fullDate')}
      </Typography>
    </KbTechEventsCard>
  );
};
