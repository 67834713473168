// RecentUpdateDrawer.tsx
import React from 'react';
import { Box, Drawer, styled, Typography } from '@mui/material';
import { KbDrawerHeader } from '../../../atoms/Card/navigationCard';
import Icon from '../../../Icons/icons';
import { IRecentUpdateDrawerProps } from '../../../../types/TechDigest';
import { MarkdownComponent } from '../../../atoms/Markdown';

const DrawerContent = styled(Box)(({ theme }) => ({
  padding: '1.5rem',
}));

const DrawerTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  paddingLeft: '.5rem',
}));

const RecentUpdateDrawer: React.FC<IRecentUpdateDrawerProps> = ({ openDrawer, handleCloseDrawer, data }) => {
  const DrawerList = (
    <Box role='presentation'>
      <KbDrawerHeader>
        <DrawerTitle variant='h4'>{data?.attributes?.title}</DrawerTitle>
        <Box paddingInline={'.5rem'} style={{ cursor: 'pointer' }} onClick={handleCloseDrawer}>
          <Icon kind={'close'} size={12} />
        </Box>
      </KbDrawerHeader>
      <DrawerContent>
        <Typography variant='body2' color='textSecondary'>
          By {data?.attributes?.author}
        </Typography>
        <Typography variant='body2' color='textSecondary' paragraph>
          Published on{' '}
          {new Date(data?.attributes?.publishedAt)?.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </Typography>
        {data?.attributes?.content && <MarkdownComponent>{data?.attributes?.content}</MarkdownComponent>}
      </DrawerContent>
    </Box>
  );

  return (
    <Drawer onClick={(e) => e.stopPropagation()} open={openDrawer} onClose={handleCloseDrawer} anchor={'right'}>
      {DrawerList}
    </Drawer>
  );
};

export default RecentUpdateDrawer;
