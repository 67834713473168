import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Skeleton, Grid, styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Heading } from '../../Heading';
import { useFetch } from '../../../Utils/hooks/useFetch';
import { formatDate } from '../../../Utils/helper';
import TechEventsModal from './TechEventsModal';
import { TechEventsCard } from '../../atoms/Card/techEventsCard';
import { ITechEvent, ITechEventResponse } from '../../../types/TechDigest';

// Styled Components
const TechEventsContainer = styled(Box)(({ theme }) => ({
  paddingBottom: '3rem',
  '& .swiper-button-next': {
    right: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
  '& .swiper-button-prev': {
    left: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
}));

const SkeletonCard = styled(Card)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  padding: '1rem',
  marginBottom: '1.5rem',
}));

const SwiperSlideContent = styled(CardContent)(({ theme }) => ({
  cursor: 'pointer',
}));

// Component
export const TechEvents: React.FC = () => {
  const [techEvents, setTechEvents] = useState<ITechEvent[]>([]);
  const [selectedTechEvent, setSelectedTechEvent] = useState<ITechEvent | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // Use the ITechEventResponse type for the response from the fetch hook
  const { data: dataSet, loading }: ITechEventResponse = useFetch('/tech-events?populate=*');

  const handleToggleDrawer = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>, event?: ITechEvent) => {
    e.stopPropagation();
    setIsDrawerOpen((prev) => !prev);
    setSelectedTechEvent(event || null);
  };

  useEffect(() => {
    if (dataSet) {
      const sortedEvents = [...dataSet].sort(
        (a, b) => new Date(b.attributes.date).getTime() - new Date(a.attributes.date).getTime()
      );
      setTechEvents(sortedEvents.slice(0, 10));
    }
  }, [dataSet]);

  if (loading) {
    return (
      <>
        <Skeleton animation='wave' variant='rectangular' width='100%' height={27} sx={{ mb: '1.5rem' }} />
        <Grid container spacing={3}>
          {[1, 2, 3].map((item, index) => (
            <Grid item xs={4} key={index}>
              <SkeletonCard>
                <Skeleton variant='rectangular' width='100%' height={130} />
                <Box sx={{ mt: 1.5 }}>
                  <Skeleton animation='wave' height={17} sx={{ marginBottom: '1rem' }} />
                  <Skeleton animation='wave' height='14' sx={{ marginBottom: '0.5rem' }} />
                  <Skeleton animation='wave' height='14' />
                </Box>
              </SkeletonCard>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <TechEventsContainer>
      <Heading title='Tech Events' variant='h3' sx={{ mb: '1.5rem' }} />
      <Swiper modules={[Navigation]} spaceBetween={50} slidesPerView={3} navigation>
        {techEvents?.map((event) => (
          <SwiperSlide key={event?.attributes?.id} className='p-0'>
            <SwiperSlideContent onClick={(e) => handleToggleDrawer(e, event)}>
              <TechEventsCard
                date={formatDate(event?.attributes?.date)}
                title={event?.attributes?.event_type}
                mediaUrl={event?.attributes?.media_url}
                alt={event?.attributes?.event_type}
                presenter={event?.attributes?.presenter}
              />
            </SwiperSlideContent>
          </SwiperSlide>
        ))}
      </Swiper>
      <TechEventsModal handleCloseModal={handleToggleDrawer} openModal={isDrawerOpen} data={selectedTechEvent} />
    </TechEventsContainer>
  );
};
