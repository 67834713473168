import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Skeleton, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFetch, IApiResponse } from '../../../Utils/hooks/useFetch';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation } from 'swiper';
import { Heading } from '../../Heading';
import { IRecentUpdate } from '../../../types/TechDigest';
import dateFormat from 'dateformat';
import RecentUpdateDrawer from './RecentUpdateDrawer';

const UpdateCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '0 1rem',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  boxSizing: 'border-box',
  height: '131px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    padding: '1.25rem',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  lineClamp: 2,
  lineHeight: 1.2,
  flex: 1,
}));

const RecentUpdatesContainer = styled(Box)(({ theme }) => ({
  paddingBottom: '3rem',
  '& .swiper-button-next': {
    right: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
  '& .swiper-button-prev': {
    left: '2px',
    '&:after': {
      fontSize: '2rem',
    },
  },
}));

// Main Component
export const RecentUpdates: React.FC = () => {
  const [recentUpdates, setRecentUpdates] = useState<IRecentUpdate[]>([]);
  const [selectedRecentUpdate, setSelectedRecentUpdate] = useState<IRecentUpdate | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { data: dataSet, loading }: IApiResponse = useFetch('/recent-updates');

  const handleToggleDrawer = (e: React.MouseEvent<HTMLElement>, data?: IRecentUpdate) => {
    e.stopPropagation();
    setIsDrawerOpen((prev) => !prev);
    setSelectedRecentUpdate(data || null);
  };

  useEffect(() => {
    if (dataSet) {
      const today = new Date();
      const sortedUpdates = [...dataSet]
        .filter((item) => {
          const publishedDate = new Date(item.attributes.publishedAt);
          return publishedDate <= today;
        })
        .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

      setRecentUpdates(sortedUpdates.slice(0, 10));
    }
  }, [dataSet]);

  if (loading) {
    return (
      <>
        <Skeleton animation='wave' variant='rectangular' width={'100%'} height={27} sx={{ mb: '1.5rem' }} />
        <Grid container spacing={3}>
          {[1, 2, 3].map((item, index) => (
            <Grid item xs={4} key={index}>
              <Card sx={{ display: 'inline-block', width: '100%', padding: '1rem', marginBottom: '3rem' }}>
                <Skeleton
                  animation='wave'
                  variant='rectangular'
                  width={'100%'}
                  height={20}
                  sx={{ marginBottom: '1.5rem' }}
                />
                <Box sx={{ mt: 1.5 }}>
                  <Skeleton animation='wave' height={17} />
                  <Skeleton animation='wave' height='17' />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <RecentUpdatesContainer>
      <Heading title='Recent Updates' variant='h3' sx={{ mb: '1.5rem' }} />
      <Swiper modules={[Navigation]} spaceBetween={50} slidesPerView={3} navigation>
        {recentUpdates?.map((update, index) => (
          <SwiperSlide key={index} className='p-0'>
            <UpdateCard>
              <CustomCardContent onClick={(e) => handleToggleDrawer(e, update)}>
                <Box sx={{ flex: 1 }}>
                  <Title variant='h6' gutterBottom>
                    {update?.attributes?.title}
                  </Title>
                </Box>
                <Box>
                  <Typography variant='body2' color='textSecondary'>
                    By {update?.attributes?.author}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    Published on {dateFormat(update?.attributes?.publishedAt, 'fullDate')}
                  </Typography>
                </Box>
              </CustomCardContent>
            </UpdateCard>
          </SwiperSlide>
        ))}
      </Swiper>
      <RecentUpdateDrawer
        handleCloseDrawer={handleToggleDrawer}
        openDrawer={isDrawerOpen}
        data={selectedRecentUpdate || ({} as IRecentUpdate)}
      />
    </RecentUpdatesContainer>
  );
};
